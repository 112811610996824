* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.MuiDataGrid-cell--withRenderer {
  p {
    padding: 0;
    margin: 0;
  }
}

.sidebar {
  margin: 0;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  background-image: linear-gradient(135deg, #362479 10%, #801f8d 100%);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 40px;
  overflow: auto;
  .top {
    margin-bottom: 20px;

    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }
  }

  hr {
    border: 1px solid rgba(230, 227, 227, 0.192);
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .center {
    padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: rgb(153, 153, 153);
        margin-top: 20px;
        margin-bottom: 0px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        margin-bottom: 10px;
        cursor: pointer;

        &:hover {
          background-color: #841dde2e;
          border-radius: 10px;

          span {
            color: rgb(255, 255, 255);
          }
        }

        .icon {
          font-size: 18px;
          color: #ac95ff;
        }

        span {
          font-size: 15px;
          font-weight: bold;
          color: rgb(235, 235, 235);
          margin-left: 10px;
        }
      }
    }
  }

  .link {
    text-decoration: none;
  }

  .active li {
    background-color: #841dde;
    border-radius: 10px;

    .icon {
      color: #ffffff;
    }

    span {
      color: rgb(255, 255, 255);
    }

    &:hover {
      background-color: #841dde !important;
    }
  }

  .main-links {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-areas: "sidebar content";
}

.sidebar {
  grid-area: sidebar;
}

.content {
  grid-area: content;
  padding: 10px 20px;
  margin-left: 40px;
  &.reports {
    margin-left: 60px;
  }
}

.app {
  .app-button {
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 10px;
    display: inline-block;
    border: 0;
    outline: 0;
    height: 37px;
    margin: 10px 0;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }

    &.app-button-primary {
      color: #fff;
      background-image: linear-gradient(135deg, #362479 10%, #801f8d 100%);

      &:hover {
        background-image: linear-gradient(
          135deg,
          #372479bf 10%,
          #801f8dc9 100%
        );
      }

      &:disabled {
        background-image: linear-gradient(
          135deg,
          #372479bf 1%,
          #801f8dc9 10%
        );
        cursor: not-allowed;
      }
    }

    &.app-button-gray {
      color: #222;
      background-image: linear-gradient(135deg, #e5e5e5 10%, #f2f2f2 100%);

      &:hover {
        background-image: linear-gradient(
          135deg,
          #e5e5e5bf 10%,
          #f2f2f2c9 100%
        );
      }
    }
  }
}
.cell-public-holiday {
  background-color: #dbdb20;
  white-space: break-spaces !important;
}
.upload-file {
  button {
    margin: 2px 0 6px 0;
    height: 42px;
  }
}
.status-pending {
  color: rgb(221, 27, 27);
}
.header3 {
  padding-top: 10px;
  font-weight: 500;
  color: #626262;
}
.no-beneficiaries {
  text-align: center;
  font-size: 13px;
  color: gray;
  padding: 16px;
  border: 1px solid #bbb7b7;
  margin: 0px 15px;
  border-radius: 4px;
}
.leave-box {
  border: 1px solid #bbb7b7;
  border-radius: 4px;
  margin: 0px 12px !important;
  padding: 7px 0px !important;
}
.beneficiaries {
  border: 1px solid #bbb7b7;
  border-radius: 4px;
  margin: 0px 12px;
  padding: 0px !important;
  font-size: 14px;

  & > div {
    display: grid;
    grid-template-columns: 5fr 2fr 1fr;
    &:not(:last-child) {
      border-bottom: 1px solid #bbb7b7;
    }
    & > div {
      &:not(:last-child) {
        border-right: 1px solid #bbb7b7;
      }
      padding: 7px 5px;
    }
  }
  & > div.header {
    font-weight: 500;
  }
}
.declare-statements {
  display: flex;
  padding-top: 20px;
  .MuiCheckbox-root {
    padding: 0px;
    padding-right: 5px;
  }
}
.declare-statements2 {
  display: flex;
  padding-top: 10px;
  .MuiCheckbox-root {
    padding: 0px;
    padding-right: 5px;
  }
}
.mode_of_travel {
  display: grid;
  grid-template-columns: 100px 1fr 1fr 240px;
  padding: 0px !important;
  margin: 0px 12px;
  gap: 7px;
  &.has-remove {
    grid-template-columns: 100px 1fr 1fr 240px 30px;
  }
  .remove-btn {
    text-align: right;
    padding-top: 30px;
    cursor: pointer;
  }
}
.province-groups {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  & > div {
    & > * {
      &:first-child {
        font-size: 11px;
        color: gray;
      }
      &.form-control {
        font-size: 12px;
        padding: 5px;
        margin: 0px;
      }
    }
  }
  & > *:last-child {
    grid-column: span 2;
  }
}
.leave-timestamp {
  font-size: 13px;
  padding-top: 12px;
}
.btn.disabled {
  cursor: default;
  color: rgb(255 255 255 / 94%) !important;
  box-shadow: none !important;
  background-color: rgb(118 118 118) !important;
  background-image: none !important;
}
.upload-sm {
  button {
    height: 37px !important;
  }
}
.employee-pie-chart {
  width: 200px;
  height: 110px;
  position: absolute;
  top: 0px;
  right: 0px;
  svg {
    border: none;
  }
  .label {
    font-size: 16px;
    color: #4a4a4a;
  }
}
.form-outline2 {
  input {
    background: none !important;
  }
}
.divisions-grid {
  .MuiDataGrid-row {
    max-height: unset !important;
    .MuiDataGrid-cell {
      max-height: unset !important;
    }
  }
}
.leave-btn-groups {
  & > button {
    font-size: 12px;
    border-color: #801f8d;
    background-image: none;
    color: #801f8d;
    &.active {
      background-image: linear-gradient(135deg, #362479 10%, #801f8d 100%);
      color: white;
    }
    &:hover {
      border-color: #801f8d !important;
    }
  }
}
.staff-form {
  .ant-form-item-label {
    padding-bottom: 3px !important;
    label {
      height: unset !important;
      padding-top: 12px;
    }
  }
  .ant-modal-footer {
    padding-top: 15px;
  }
}
.staff-add-new {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  .ant-form-item-explain {
    margin-top: 5px !important;
    font-size: 12px !important;
  }
  .no-autocomplete {
    .ant-form-item-explain {
      margin-top: -6px !important;
      font-size: 12px !important;
    }
  }
}
.leave-creadits {
  padding-top: 50px;
  & > div {
    display: grid;
    grid-template-columns: 50px 1fr 1fr 1fr 1fr;
    margin: 0px;
    padding: 0px;
  }
}
.Toastify__toast-container:has(> .big-toast) {
  width: 500px !important;
}
